import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Button, Container, Typography } from "@mui/material";
import "./SplitSection.css";
import img1 from "../assets/img1.jpeg";

import AnimatedText from "react-animated-text-content";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function SplitSection(props) {
  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <AnimatedText
        type="chars"
        style={{
          textShadow: "1px 1px 2px black",
          fontSize: "3rem",
          color: props.color || "gray",
        }} // animate words or chars
        animation={{
          x: "200px",
          y: "-20px",
          scale: 3,
          ease: "ease-in-out",
        }}
        animationType="light"
        interval={0.06}
        duration={0.8}
        tag="p"
        className="animated-paragraph"
        includeWhiteSpaces
        threshold={0.1}
        rootMargin="20%"
      >
        {props.heading}
      </AnimatedText>

      {/* <h1
        style={{ display: "flex", fontSize: "3.0rem", alignItems: "center" }}
      ></h1> */}
      <p
        style={{
          display: "flex",
          alignItems: "center",
          color: "#555555",
          fontWeight: "bolder",
          fontFamily: "Montserrat",
        }}
      >
        {props.description}
      </p>
    </Container>
  );
}
