import { Box, Button, Card, Backdrop, Fade, CardContent, CardHeader, CardMedia, Container, IconButton, makeStyles, Modal, Typography, Paper } from '@mui/material'
import React, { useState } from 'react'
import AnimatedText from 'react-animated-text-content'
import Carousel from 'react-material-ui-carousel';
import patentImg from '../assets/patent.jpg'
import Sectionimage from '../components/Sectionimage';
import SplitSection from '../components/SplitSection';
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    heightMax: "100%",
    bgcolor: "background.paper",
    boxShadow: 24,
    overFlow: "auto",
    p: 4,
};
const Patent = () => {
    const [modalContent, setModalContent] = useState(false)
    const handleModal = () => {
        setModalContent(true)
    }
    const handleClose = () => {
        setModalContent(false)
    }
    return (
        <>
            {modalContent && <Modal
                // className={classes.modal}
                open={modalContent}
                onClose={handleClose}
                closeAfterTransition

            >
                <Paper variant="outlined" style={{
                    display: 'flex', justifyContent: 'center', maxHeight: '100vh'
                }}>
                    <div style={{ display: 'flex', marginTop: '4.5rem', flexDirection: 'column' }}>
                        <img src={patentImg} width='600px' style={{ marginTop: '5rem' }} />
                        <Button onClick={handleClose}>Close</Button>


                    </div>

                </Paper>



            </Modal>}
            <div>
                {/* <SplitSection heading={'Shaping Idea Into Practice'} /> */}
                <Sectionimage name={'SHAPING IDEA INTO PRACTICE'} source={'https://imageio.forbes.com/specials-images/imageserve/1138155024/0x0.jpg?format=jpg&width=1200'} />
                {/* <Container
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <AnimatedText
                        type="chars"
                        style={{
                            fontSize: "3rem",
                        }} // animate words or chars
                        animation={{
                            x: "200px",
                            y: "-20px",
                            scale: 3,
                            ease: "ease-in-out",
                        }}
                        animationType="light"
                        interval={0.06}
                        duration={0.8}
                        tag="p"
                        className="animated-paragraph"
                        includeWhiteSpaces
                        threshold={0.1}
                        rootMargin="20%"
                    >
                        Shaping Idea Into Practice

                    </AnimatedText>

                 

                </Container> */}
                <Container Container style={{ maxWidth: '100vw' }}>

                    <Card sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }} >

                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardContent sx={{ flex: '1 0 auto' }}>
                                <Typography component="div" variant="h5">
                                    PATENT
                                </Typography>
                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                    <div style={{
                                        textAlign: 'justify', textJustify: 'inter-word'
                                    }}>
                                        A Patent is a statutory territorial right granted to owners of an invention (product or process) conferring privileges to exclude others from making, using, selling, importing the patented product or process for producing that product for those purposes without their consent for a limited time period.
                                    </div>
                                    <hr />
                                    <div style={{
                                        textAlign: 'justify', textJustify: 'inter-word'
                                    }}>The patent system in India is governed by the Patents Act, 1970 and the Patents Rules, 2003.
                                    </div>
                                    <hr />

                                    <div style={{
                                        textAlign: 'justify', textJustify: 'inter-word'
                                    }}>An invention relating either to a product or process that is new, involving inventive step and capable of industrial application can be patented. However, it must not fall into the categories of inventions that are non-patentable under sections 3 and 4 of the Patents Act, 1970.
                                    </div>
                                    <hr />

                                    <div style={{
                                        textAlign: 'justify', textJustify: 'inter-word'
                                    }}>The term of every patent granted in India is 20 years from the date of filing of application. However, for application filed under national phase under Patent Cooperation Treaty (PCT), the term of patent will be 20 years from the international filing date accorded under PCT.
                                    </div>
                                    <hr />

                                    <div style={{
                                        textAlign: 'justify', textJustify: 'inter-word'
                                    }}>Our clienteles include individual inventors, institutes/universities, start-ups, MSMEs and corporate in various domains such as Mechanical & Machinery, IT & Software, Electronics & Telecommunications, Automobile, Electro-mechanical, Civil, Medical/Healthcare, pharmaceuticals, chemicals, biotechnology and many more such industry verticals.
                                    </div>
                                    <hr />

                                    <div style={{
                                        textAlign: 'justify', textJustify: 'inter-word'
                                    }}>India is a signatory to the Agreement on Trade Related Aspects of Intellectual Property Rights (TRIPS), the Paris Convention and the Patent Co-operation Treaty (PCT), thereby enabling patent applicants to protect their rights in multiple countries without losing priority.
                                    </div>
                                    <hr />
                                    <div style={{
                                        textAlign: 'justify', textJustify: 'inter-word'
                                    }}>We render the following comprehensive patent services:
                                        <ul>




                                            <li> Prior Art searches</li>
                                            <li>   Evaluating Patenting Feasibility & Giving Advice</li>
                                            <li>   Drafting Specification/Application</li>
                                            <li>  Patent (ordinary, convention, PCT national phase) Application Filing</li>
                                            <li>PCT International Application Filing</li>
                                            <li>  Patent prosecution</li>
                                            <li>Response to Office Action (Examination/Hearing)</li>
                                            <li>  Follow-up with patent office for grant</li>
                                            <li>   Annuity Payments</li>
                                            <li> Annual Statement of Working</li>
                                            <li>  Recordal of Assignment, Licensing, & Alteration</li>
                                            <li>  Foreign Filing License</li>
                                            <li>   Application Withdrawal</li>
                                            <li>   Pre-grant and Post-grant oppositions</li>
                                            <li> Patent Portfolio Management</li>

                                        </ul>
                                    </div>
                                </Typography>

                            </CardContent>
                            <Button onClick={handleModal}>
                                Click to view the flowchart!!
                            </Button>
                        </Box>

                    </Card></Container></div></>
    )
}

export default Patent


