import React from "react";
import "./App.css";
import Home from "./pages/Home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Services from "./pages/Services";

import Footer from "./components/Footer";
import Nav2 from './components/Nav2'
import './App.css'
import Patent from "./pages/Patent";
import Commercialisation from "./pages/Commercialisation";
import Trademark from "./pages/Trademark";
import Design from "./pages/Design";
import Updates from "./pages/Updates";
import Team from "./pages/Team";
import Contact from "./pages/Contact";
import NavSec from "./components/NavSec";
;
function App() {
  return (

    <Router>
      <NavSec />
      <Nav2 />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/services" component={Services} />
        <Route path="/patent" component={Patent} />
        <Route path="/design" component={Design} />
        <Route path="/trademark" component={Trademark} />
        <Route path="/commercialisation" component={Commercialisation} />
        <Route path="/insight" component={Updates} />
        <Route path="/team" component={Team} />
        <Route path="/contact" component={Contact} />

        {/* <Route path="/products" component={Products} /> */}
        {/* <Route path="/contact-us" component={ContactUs} /> */}
        {/* <Route path="/sign-up" component={SignUp} /> */}
        {/* <Route path="/marketing" component={Marketing} /> */}
        {/* <Route path="/consulting" component={Consulting} /> */}
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
