import React from "react";
import Carousel from "react-material-ui-carousel";
import { Paper } from "@mui/material";
import SplitSection from "./SplitSection";
import img1 from "../assets/img1.jpeg";

function Item(props) {
  return (
    <Paper
      style={{
        display: "flex",
        backgroundImage: `url(${props.item.source})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100vw",
        height: "70vh",
        alignItems: "center",
      }}
    >
      <SplitSection
        heading={props.item.name}
        description={props.item.description}
        image={props.item.source}
        color={props.item.color || "black"}
      />
    </Paper>
  );
}

const SlickSlider = () => {
  var items = [
    {
      name: "COMMITTED TO PROTECTING INNOVATION",
      color: "#4E97D1",
      source:
        "https://images.squarespace-cdn.com/content/v1/5659f669e4b0f60cdbb12aa8/1491601775332-XHNIDNDOH9TSFRWFDKNH/shutterstock_532276003.jpg?format=1500w",
    },
    // {
    //   name: "SHAPING IDEA INTO PRACTICE",

    //   source:
    //     "https://imageio.forbes.com/specials-images/imageserve/1138155024/0x0.jpg?format=jpg&width=1200",
    // },
    // {
    //   name: "Beautifying Articles.",
    //   // description:
    //   source:
    //     "https://m.economictimes.com/thumb/msid-86417219,width-1200,height-900,resizemode-4,imgsize-83036/1-istock.jpg",
    // },
    // {
    //   name: "Establishing Identity.",
    //   // description:
    //   source: "https://www.livelaw.in/h-upload/images/trademark-protection.jpg",
    // },
  ];

  return (
    <Carousel
      indicatorIconButtonProps={{
        style: {
          marginBottom: "50px",

          marginTop: "-80px", // 1      // 3
        },
      }}
      activeIndicatorIconButtonProps={{
        style: {
          backgroundColor: "#008000",
        },
      }}
    >
      {items.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  );
};

export default SlickSlider;
