import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Button, Container, Grid, Paper } from "@mui/material";
import ach1 from "../assets/1.jpg";
import ach2 from "../assets/2.jpg";
import ach3 from "../assets/3.jpg";
import ach4 from "../assets/4.jpg";
import ach5 from "../assets/5.jpg";
import ach6 from "../assets/6.jpg";

import ach7 from "../assets/7.jpg";

import SplitSection from "./SplitSection";
import Carousel from "react-material-ui-carousel";
var items = [
  {
    source: `${ach1}`,
  },
  {
    source: `${ach2}`,
  },
  {
    source: `${ach3}`,
  },
  {
    source: `${ach4}`,
  },
  {
    source: `${ach5}`,
  },
  {
    source: `${ach6}`,
  },
  {
    source: `${ach7}`,
  },
];
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function HomeCard() {
  return (
    <Container>
      {" "}
      <Grid
        container
        spacing={2}
        // style={{ display: "flex", gap: "2rem", justifyContent: "space-evenly" }}
      >
        <Grid item xs={7}>
          <CardContents
            heading={"WELCOME TO 'SKD PATENT SERVICES'"}
            p1={`Our seasoned IP professionals have diverse experience in myriad industry verticals. Our exclusive focus is on rendering all kind of services related to patents, (industrial) designs, and trademarks in India as well as in foreign countries. We have collaborations with the professional associates at every major jurisdiction across the globe.`}
            p2={` The underlying technicalities of IP subject may not always be easy to comprehend, however our techno-legal experts are committed to navigate the clients through each step with straightforward opinion. `}
            p3={`   We distinguish ourselves through prompt service delivery with quality, depth of knowledge, and success in each of our service areas. Each client is important to us; therefore, we are flexible to provide customized service at an affordable cost to fulfil each client’s requirement.`}
            btnText={"Learn More"}
            type={"text"}
          />
        </Grid>
        <Grid item xs={5}>
          <CardContents heading={"Achievements"} type={"image"} />
        </Grid>
      </Grid>
    </Container>
  );
}

function CardContents(props) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <Card sx={{ maxWidth: "100%" }}>
      <CardHeader
        action={<IconButton aria-label="settings"></IconButton>}
        title={props.heading}
        // subheader="September 14, 2016"
      />
      {props.type === "text" && (
        <CardContent>
          <div
            style={{
              textAlign: "justify",
              textJustify: "inter-word",
            }}
          >
            <Typography variant="body3" color="text.secondary">
              {props.p1}
            </Typography>
            <hr />
            <Typography variant="body3" color="text.secondary">
              {props.p2}
            </Typography>
            <hr />
            <Typography variant="body3" color="text.secondary">
              {props.p3}
            </Typography>
          </div>
        </CardContent>
      )}

      {props.type === "image" && (
        <CardContent>
          <Carousel
            style={{
              display: "flex",
              justifyContent: "center",
            }}
            indicatorIconButtonProps={{
              style: {
                marginBottom: "50px",

                marginTop: "-80px", // 1      // 3
              },
            }}
            activeIndicatorIconButtonProps={{
              style: {
                backgroundColor: "#008000",
              },
            }}
          >
            {items.map((item, i) => (
              <Item key={i} item={item} />
            ))}
          </Carousel>
        </CardContent>
      )}
    </Card>
  );
}

function Item(props) {
  return (
    <Paper
      style={{
        display: "block",
        backgroundImage: `url(${props.item.source})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "",
        width: "26vw",
        height: "250px",
        alignItems: "center",
      }}
    ></Paper>
  );
}
