import { Container } from '@mui/material'
import React from 'react'
import FaqComp from '../components/Faq'
import Sectionimage from '../components/Sectionimage'
const Updates = () => {
    return (<>
        <Sectionimage name={'NEWS & ARTICLES'} source={'https://majestylegal.in/wp-content/uploads/2022/12/Legal-news-in-brief.jpg'} />

        <Container>
            <FaqComp />
        </Container></>
    )
}

export default Updates
