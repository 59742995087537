import React from "react";
import { Link } from "react-router-dom";
import logo from '../assets/logo-skd.png'
import './Nav.css'
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
function NavSec() {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-dark static-top header-a sticky"  >
      <div className="container nav-container" style={{ display: 'flex' }}>





        <div


        >

          <li className="nav-item nav-primary-content">
            <a className="nav-link" href="tel:+919830962871">
              <CallIcon /> (+91) 9830962871
            </a>
          </li>

        </div>
        <div
        >
          <li className="nav-item nav-primary-content" >
            <a className="nav-link" href="mailto: mail@skdpatent.com">
              <EmailIcon /> mail@skdpatent.com
            </a>
          </li>
        </div>
      </div>
    </nav >
  );
}

export default NavSec;
