import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import Faq from "react-faq-component";
import pdf1 from "../assets/FAQ-Patent.pdf";
import pdf2 from "../assets/FAQ-Design.pdf";
import pdf3 from "../assets/FAQ-Trademark.pdf";

const data = {
    title: "FAQ (Important Content!!)",
    rows: [
        {
            title: "FAQ Patent",
            content: (
                <a href={pdf1} download="FAQ-Patent.pdf">
                    Download Patent FAQ
                </a>
            ),
        },
        {
            title: "FAQ Design",
            content: (
                <a href={pdf2} download="FAQ-Design.pdf">
                    Download Design FAQ
                </a>
            ),
        },

        {
            title: "FAQ Trademark",
            content: (
                <a href={pdf3} download="FAQ-Trademark.pdf">
                    Download Trademark FAQ
                </a>
            ),
        },
        {
            title: <h2>Important Links!!!</h2>,
            content: (
                <ul>
                    <li>
                        <a href="https://ipindia.gov.in/writereaddata/Portal/ev/sections-index.html">Indian Patents Act</a>

                    </li>
                    <li>
                        <a href="https://ipindia.gov.in/writereaddata/Portal/ev/rules-index.html">Indian Patents Rules</a>


                    </li>
                    <li>
                        <a href="https://ipindiaservices.gov.in/PublicSearch/"> Indian Patent Search</a>


                    </li><li>
                        <a href="https://ipindia.gov.in/designs-act-2000.htm">Indian Designs Act</a>


                    </li><li>
                        <a href="https://ipindia.gov.in/rules-designs.htm">Indian Designs Rule </a>


                    </li><li>
                        <a href="https://search.ipindia.gov.in/designsearch">Indian Design Search</a>


                    </li>
                    <li>
                        <a href="https://ipindia.gov.in/writereaddata/Portal/ev/TM-Amendment-Act-2010.html">Indian Trademarks Act</a>


                    </li>
                    <li>
                        <a href="https://ipindia.gov.in/TM-Rules-2017.htm">Indian Trademarks Rules</a>


                    </li>
                    <li>
                        <a href="https://ipindiaonline.gov.in/eregister/eregister.aspx">Indian Trademark Search</a>


                    </li>
                </ul>
            ),
        },
    ],
};

const styles = {
    // bgColor: 'white',
    titleTextColor: "black",
    rowTitleColor: "black",
    // rowContentColor: 'grey',
    // arrowColor: "red",
};

const config = {
    // animate: true,
    // arrowIcon: "V",
    tabFocus: true
};

export default function FaqComp() {
    return (
        <div>


            <Faq data={data} styles={styles} config={config} />
        </div>
    );
}
