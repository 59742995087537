import { Box, Button, Card, Backdrop, Fade, CardContent, CardHeader, CardMedia, Container, IconButton, makeStyles, Modal, Typography, Paper } from '@mui/material'
import React, { useState } from 'react'
import AnimatedText from 'react-animated-text-content'
import patentImg from '../assets/patent.jpg'
import Sectionimage from '../components/Sectionimage';
import SplitSection from '../components/SplitSection';
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    heightMax: "100%",
    bgcolor: "background.paper",
    boxShadow: 24,
    overFlow: "auto",
    p: 4,
};
const Trademark = () => {
    const [modalContent, setModalContent] = useState(false)
    const handleModal = () => {
        setModalContent(true)
    }
    const handleClose = () => {
        setModalContent(false)
    }
    return (
        <>
            {modalContent && <Modal
                // className={classes.modal}
                open={modalContent}
                onClose={handleClose}
                closeAfterTransition

            >
                <Paper variant="outlined" style={{
                    display: 'flex', justifyContent: 'center', maxHeight: '100vh'
                }}>
                    <div style={{ display: 'flex', marginTop: '4.5rem', flexDirection: 'column' }}>
                        <img src={patentImg} width='900px' />
                        <Button onClick={handleClose}>Close</Button>


                    </div>

                </Paper>



            </Modal>}
            <div>
                {/* <SplitSection heading={'Establishing Identity'} /> */}
                <Sectionimage name={'ESTABLISHING IDENTITY'} source={'https://assets-news.housing.com/news/wp-content/uploads/2022/06/14174432/shutterstock_2032604168-1200x700-compressed.jpg'} />

                {/* <Container
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <AnimatedText
                        type="chars"
                        style={{
                            fontSize: "3rem",
                        }} // animate words or chars
                        animation={{
                            x: "200px",
                            y: "-20px",
                            scale: 3,
                            ease: "ease-in-out",
                        }}
                        animationType="light"
                        interval={0.06}
                        duration={0.8}
                        tag="p"
                        className="animated-paragraph"
                        includeWhiteSpaces
                        threshold={0.1}
                        rootMargin="20%"
                    >
                        Shaping Idea Into Practice

                    </AnimatedText>

                 

                </Container> */}
                <Container Container style={{ maxWidth: '100vw' }}>

                    <Card sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }} >

                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardContent sx={{ flex: '1 0 auto' }}>
                                <Typography component="div" variant="h5">
                                    TRADEMARK
                                </Typography>
                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                    <div style={{
                                        textAlign: 'justify', textJustify: 'inter-word'
                                    }}>A Trademark (Service Mark) is a statutory territorial right given for a distinctive sign or indicator which is used by an individual or any entity to represent the goods and services manufactured and marketed by that party.
                                    </div>
                                    <hr />
                                    <div style={{
                                        textAlign: 'justify', textJustify: 'inter-word'
                                    }}>A Trademark may be anything from a word, symbol, letter, sound, smell, shape, colour combination, three-dimensional mark which distinguish the goods and services covered under it from that of other third parties.

                                    </div>
                                    <hr />

                                    <div style={{
                                        textAlign: 'justify', textJustify: 'inter-word'
                                    }}>The Trademark system in India is governed by Indian Trade Marks Act, 1999 and Trade Marks Rules, 2017.

                                    </div>
                                    <hr />

                                    <div style={{
                                        textAlign: 'justify', textJustify: 'inter-word'
                                    }}>The registration of trademark in India is initially valid for 10 years from the date of filing of the trademark application and can be renewed for each subsequent ten years period by paying renewal fee. The payment window for annuities starts from six months before the due date.

                                    </div>
                                    <hr />

                                    <div style={{
                                        textAlign: 'justify', textJustify: 'inter-word'
                                    }}>India is a signatory to Madrid protocol and allows International trademark application through Madrid system.
                                    </div>

                                    <hr />
                                    <div style={{
                                        textAlign: 'justify', textJustify: 'inter-word'
                                    }}>We render the following comprehensive trademark services:

                                        <ul>

                                            <li>   Trademark Search & Opinion</li>
                                            <li>   Drafting Specification of Goods & Services</li>
                                            <li>    Trademark (Basic, Convention, IR designating India) Application</li>
                                            <li>   International Registration (IR) under Madrid Agreement</li>
                                            <li>   Response to Office Action (Examination/Hearing)</li>
                                            <li>   Follow-up For Registration</li>
                                            <li>   Annuity Payments</li>
                                            <li>    Recordal of Assignment, Licensing, & Alteration</li>
                                            <li>   Trademark Oppositions</li>
                                            <li>   Trademark Cancellations</li>
                                            <li>   Trademark Portfolio Management</li>

                                        </ul>
                                    </div>
                                </Typography>

                            </CardContent>

                        </Box>

                    </Card></Container></div></>
    )
}

export default Trademark
