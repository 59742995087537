import { Paper } from '@mui/material'
import React from 'react'
import Carousel from 'react-material-ui-carousel'
import SplitSection from './SplitSection'

function Sectionimage(props) {
    return (
        <Carousel
        // indicatorIconButtonProps={{
        //     style: {
        //         marginBottom: "50px",

        //         marginTop: "-80px", // 1      // 3
        //     },
        // }}
        // activeIndicatorIconButtonProps={{
        //     style: {
        //         backgroundColor: "#008000",
        //     },
        // }}
        >
            <Paper
                style={{
                    display: "flex",
                    backgroundImage: `url(${props.source})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    width: "100vw",
                    height: "70vh",
                    alignItems: "center",
                }}
            >
                <SplitSection
                    heading={props.name}
                    color={props.color}
                />
            </Paper>
        </Carousel>)
}

export default Sectionimage
