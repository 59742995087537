import { Box, Card, CardContent, CardMedia, Container, Typography } from '@mui/material'
import React from 'react'
import Sectionimage from '../components/Sectionimage'
import skd from '../assets/skd.jpg'
function CardContentInfo(props) {
    // const navigate = useNavigate();
    return (<Card sx={{ width: '500px' }}>
        <CardMedia
            sx={{ height: 300 }}
            image={props.image}

        />
        <CardContent>
            <Typography gutterBottom variant="h5" component="div">
                {props.heading}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                {props.subheading}
            </Typography>
        </CardContent>

    </Card>)
}
const Team = () => {
    return (
        (
            <>

                <div>
                    <Sectionimage color={'white'} name={'OUR TEAM'} source={'https://le-cdn.hibuwebsites.com/1ccea5dea66e40ec9704ca34049a2d69/dms3rep/multi/opt/neil-law-firm-hero-home-1920w.jpg'} />


                    <Container Container style={{ maxWidth: '100vw' }}>
                        <Container style={{ display: 'flex', flexDirection: 'column', gap: '1.5rem', }}>
                            <div style={{
                                display: 'flex',
                                textAlign: 'justify', textJustify: 'inter-word'
                            }}>
                                <h5>
                                    Our team comprises patent and trademark attorneys, technical experts, advocates, and support staffs having decades of experience in registration, protection, enforcement and commercial exploitation of patents, industrial designs, and trademarks in India. Our client-centric focus enables us to consistently provide responsive, value-driven, cost-effective, and time-efficient services to our esteemed clients.
                                </h5>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', gap: '1.5rem', marginBottom: '1.5rem' }}>
                                <CardContentInfo
                                    heading={'Mr. Suman Kumar Das'}
                                    subheading={' (Managing Director, Indian Patent Attorney)'}
                                    image={skd} />
                                <CardContentInfo heading={'Mr. Arunava Mukherjee'}
                                    subheading={' (IPR Attorney, Advocate)'} />

                                <CardContentInfo heading={'Mr. Sujay Das'}
                                    subheading={'(Paralegal Head)'} />

                            </div>

                        </Container>
                    </Container></div></>
        )
    )
}

export default Team
