import React from "react"
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
function Footer() {
    return (
        <footer class="page-footer font-small blue footer" >

            <div class="footer-copyright text-center py-3">2023 © SKD PATENT SERVICES . All Rights Reserved  &nbsp;
                <LinkedInIcon />
                <FacebookIcon />
                <TwitterIcon />

            </div>

        </footer>
    );
}

export default Footer;