import React, { useEffect, useState } from 'react';
import SlickSlider from '../components/SlickSlider';
import Cards from '../components/Cards'
import Backdrop from '@mui/material/Backdrop';

import { Box, Button, Container, Fade, Modal, Typography } from '@mui/material';
import Services from './Services';
// import { Contact } from './contact';
export default function Home() {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vw',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const [modalState, setModalState] = useState(false);
  const handleClose = () => {
    window.localStorage.setItem('cookie', true)
    setModalState(false)
  }
  useEffect(() => {
    const c = window.localStorage.getItem('cookie')
    if (!c) {
      setTimeout(() => setModalState(true), 1200);

    }

  }, [])

  return (
    <>
      {modalState &&

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={modalState}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 3000,
          }}
        >
          <Fade in={modalState}>
            <Box sx={style}>
              <Typography id="transition-modal-title" variant="h6" component="h2">
                Disclaimer
              </Typography>
              <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                <div style={{
                  textAlign: 'justify', textJustify: 'inter-word'
                }}>  The content of this website is for informational purposes only and should not be interpreted as soliciting or advertisement. No material/information provided on this website should be construed as legal advice for which the user must make independent inquiries. 'SKD PATENT SERVICES' disclaims all liability arising from reliance placed by the user or any other third party on the information contained therein. </div>
              </Typography>
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Button onClick={handleClose}>I AGREE</Button>
              </div>
            </Box>

          </Fade>
        </Modal>}
      <SlickSlider />
      <Container>
        <div style={{
          display: 'flex',
          textAlign: 'justify', textJustify: 'inter-word'
        }}>
          <h5>
            ‘SKD PATENT SERVICES’ is a boutique firm specialized in registration, protection, and commercialization of intellectual property (IP). The firm is headquartered in Kolkata - the 'Intellectual Capital' of India, where the 'Kolkata Patent Office' is currently the principal office for Patent and Design Registration in India.
          </h5>
        </div>
      </Container>
      <Services />
      <Cards />


      {/* <Contact /> */}
    </>
  );
}
