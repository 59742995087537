import { Box, Button, Card, Backdrop, Fade, CardContent, CardHeader, CardMedia, Container, IconButton, makeStyles, Modal, Typography, Paper } from '@mui/material'
import React, { useState } from 'react'
import AnimatedText from 'react-animated-text-content'
import patentImg from '../assets/patent.jpg'
import Sectionimage from '../components/Sectionimage';
import SplitSection from '../components/SplitSection';
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    heightMax: "100%",
    bgcolor: "background.paper",
    boxShadow: 24,
    overFlow: "auto",
    p: 4,
};
const Design = () => {
    const [modalContent, setModalContent] = useState(false)
    const handleModal = () => {
        setModalContent(true)
    }
    const handleClose = () => {
        setModalContent(false)
    }
    return (
        <>

            <div>
                <Sectionimage name={'BEAUTIFYING ARTICLES'} source={'https://www.istitutomarangoni.com/marangoni/entities/article/CS-INTERIOR-DESIGN-JOB_IMG_D.jpg'} />


                <Container Container style={{ maxWidth: '100vw' }}>

                    <Card sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }} >

                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardContent sx={{ flex: '1 0 auto' }}>
                                <Typography component="div" variant="h5">
                                    DESIGN

                                </Typography>
                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                    <div style={{
                                        textAlign: 'justify', textJustify: 'inter-word'
                                    }}>An (Industrial) Design is a statutory territorial right given to proprietors for aesthetic features of shape, configuration, pattern, ornament or composition of lines or colours applied to any article whether in two dimensional or three dimensional or in both forms; conferring exclusive privileges to apply/use/commercialize the registered designs under specific classes for a limited time period.
                                    </div>
                                    <hr />
                                    <div style={{
                                        textAlign: 'justify', textJustify: 'inter-word'
                                    }}>A Design is primarily of an aesthetic nature (to be judged solely by the eyes) and therefore, the technical features and functional aspects of the products are not protected under Designs. A Design must be new and original in order to be registrable under specific classes as specified in an international classification system. It should not have been disclosed to the public anywhere in world and should not be contrary to public order or morality.

                                    </div>
                                    <hr />

                                    <div style={{
                                        textAlign: 'justify', textJustify: 'inter-word'
                                    }}>The design registration in India is governed by the Designs Act, 2000. Sometimes purchase of articles for use is influenced not only by their practical efficiency but also by their appearance. The important purpose of design Registration is to see that the artisan, creator, originator of a design having aesthetic look is not deprived of his bonafide reward by others applying it to their goods.

                                    </div>
                                    <hr />

                                    <div style={{
                                        textAlign: 'justify', textJustify: 'inter-word'
                                    }}>The term of every registered design in India is initially 10 years from the date of registration, but in cases where claim to priority has been allowed the term is 10 years from the priority date. This initial period of registration may be extended by further period of 5 years on an application made to the Controller before the expiry of the said initial period of ten years.

                                    </div>
                                    <hr />

                                    <div style={{
                                        textAlign: 'justify', textJustify: 'inter-word'
                                    }}>Our clienteles include individual inventors, institutes/universities, start-ups, MSMEs and corporate engaged in various business covering industrial products, handicrafts, technical and medical instruments, watches and jewellery, fashions, electrical appliances, automotive products, etc.

                                    </div>
                                    <hr />

                                    <hr />
                                    <div style={{
                                        textAlign: 'justify', textJustify: 'inter-word'
                                    }}>We render the following comprehensive design services:

                                        <ul>

                                            <li>Design Search</li>
                                            <li>  Evaluating Registration Feasibility & Giving Advice</li>
                                            <li>Drafting Application</li>
                                            <li>Design (Basic and Convention) Application Filing</li>
                                            <li>Design prosecution</li>
                                            <li>Response to Office Action (Examination/Hearing)</li>
                                            <li> Follow-up For Registration</li>
                                            <li>Annuity Payments</li>
                                            <li>Recordal of Assignment, Licensing, & Alteration</li>
                                            <li>Design Cancellations</li>
                                            <li>Design Portfolio Management</li>

                                        </ul>
                                    </div>
                                </Typography>

                            </CardContent>

                        </Box>

                    </Card></Container></div></>
    )
}

export default Design
