import { Box, Button, Card, Backdrop, Fade, CardContent, CardHeader, CardMedia, Container, IconButton, makeStyles, Modal, Typography, Paper } from '@mui/material'
import React, { useState } from 'react'
import AnimatedText from 'react-animated-text-content'
import patentImg from '../assets/patent.jpg'
import Sectionimage from '../components/Sectionimage';
import SplitSection from '../components/SplitSection';
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    heightMax: "100%",
    bgcolor: "background.paper",
    boxShadow: 24,
    overFlow: "auto",
    p: 4,
};
const Commercialisation = () => {
    const [modalContent, setModalContent] = useState(false)
    const handleModal = () => {
        setModalContent(true)
    }
    const handleClose = () => {
        setModalContent(false)
    }
    return (
        <>
            {modalContent && <Modal
                // className={classes.modal}
                open={modalContent}
                onClose={handleClose}
                closeAfterTransition

            >
                <Paper variant="outlined" style={{
                    display: 'flex', justifyContent: 'center', maxHeight: '100vh'
                }}>
                    <div style={{ display: 'flex', marginTop: '4.5rem', flexDirection: 'column' }}>
                        <img src={patentImg} width='900px' />
                        <Button onClick={handleClose}>Close</Button>


                    </div>

                </Paper>



            </Modal>}
            <div>
                {/* <SplitSection heading={'Commercializing Innovation'} /> */}
                <Sectionimage name={'COMMERCIALIZING INNOVATION'} source={'https://iaam.se/img/Commercializing%20Technology/Your%20idea%20our%20productionzation.jpg'} />

                {/* <Container
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <AnimatedText
                        type="chars"
                        style={{
                            fontSize: "3rem",
                        }} // animate words or chars
                        animation={{
                            x: "200px",
                            y: "-20px",
                            scale: 3,
                            ease: "ease-in-out",
                        }}
                        animationType="light"
                        interval={0.06}
                        duration={0.8}
                        tag="p"
                        className="animated-paragraph"
                        includeWhiteSpaces
                        threshold={0.1}
                        rootMargin="20%"
                    >
                        Shaping Idea Into Practice

                    </AnimatedText>

                 

                </Container> */}
                <Container Container style={{ maxWidth: '100vw' }}>

                    <Card sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }} >

                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardContent sx={{ flex: '1 0 auto' }}>
                                <Typography component="div" variant="h5">
                                    COMMERCIALIZATION
                                </Typography>
                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                    <div style={{
                                        textAlign: 'justify', textJustify: 'inter-word'
                                    }}>In an increasingly technology driven world, tremendous wealth can be tapped by building and nurturing an effective IP portfolio. We are privileged in serving our clients to derive value from their patented technology and registered design/trademark through appropriate mechanisms such as assignment, licensing, technology transfer agreements, etc. This often involves helping the clients navigate through complex issues relating to SEPs (Standard Essential Patents) and FRAND (Fair Reasonable and Non-Discriminatory) terms.
                                    </div>
                                    <hr />
                                    <div style={{
                                        textAlign: 'justify', textJustify: 'inter-word'
                                    }}>We cater to the need of training of innovators, researchers, tech enthusiasts, particularly imparting awareness of IP system among individuals, academia, start-up who are interested or involved in ideation, creation, protection, and commercialization of patents/designs rights.
                                    </div>
                                    <hr />

                                    <div style={{
                                        textAlign: 'justify', textJustify: 'inter-word'
                                    }}>We also carry out IP Audits and due-diligence for our clients by undertaking a comprehensive and thorough review of their IP assets, related agreements and relevant policies and give strategic advice as to optimum utilization of their IP. Our IP Audit Reports form part of Merger & Acquisition transactions, asset purchases and business transfers and a host of other functions. </div>



                                </Typography>

                            </CardContent>

                        </Box>

                    </Card></Container></div></>
    )
}

export default Commercialisation
