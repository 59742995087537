import { Box, Card, CardContent, CardMedia, Container, Typography } from '@mui/material'
import React from 'react'
import Sectionimage from '../components/Sectionimage'
function CardContentInfo(props) {
    // const navigate = useNavigate();
    return (<Card sx={{ width: '500px' }}>

        <CardContent>
            <Typography gutterBottom variant="h5" component="div">
                {props.heading}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                {props.subheading}
            </Typography>
        </CardContent>

    </Card>)
}
const Contact = () => {
    return (
        (
            <>

                <div>
                    <Sectionimage source={'https://planetofhotels.com/guide/sites/default/files/styles/paragraph__hero_banner__hb_image__1880bp/public/hero_banner/Howrah_Bridge.jpg'} />


                    <Container Container style={{ maxWidth: '100vw' }}>
                        <Container style={{ display: 'flex', flexDirection: 'column', gap: '1.5rem', }}>

                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', gap: '1.5rem', marginBottom: '1.5rem' }}>
                                <CardContentInfo
                                    heading={'Registered Address'}
                                    subheading={
                                        <p>SKD PATENT SERVICES <br />Ground Floor, 142 G. T. Road<br />Uttarpara, Hooghly-712258<br /> West Bengal, India. </p>
                                    } />
                                <CardContentInfo heading={'Visiting Address'}
                                    subheading={<p>18, W.C.Banerjee Street,<br />Near Girish Park Metro Station,<br /> Kolkata - 700006, West Bengal, India. </p>
                                    } />
                                <CardContentInfo heading={'Associate Office'}
                                    subheading={' Located in Delhi, Mumbai, Chennai'} />

                                <CardContentInfo heading={'Contact Details'}
                                    subheading={<ul>
                                        <li>Mobile: (+91) 9830962871</li>
                                        <li>Email: mail@skdpatent.com</li>
                                        {/* <li>Location: <a href='https://maps.app.goo.gl/3KiW21zRM3aXv1rU9?g_st=iw'>Google Maps</a></li> */}
                                    </ul>}
                                />

                            </div>

                        </Container>
                    </Container></div></>
        )
    )
}

export default Contact
