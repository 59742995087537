import { Button, Card, CardActions, CardContent, CardMedia, Container, Typography } from '@mui/material';
import React from 'react'
import { UserCard } from 'react-ui-cards';
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

function CardContentInfo(props) {
  // const navigate = use Navigate();
  let history = useHistory();

  return (<Card sx={{ width: '500px' }}>
    <CardMedia
      sx={{ height: 140 }}
      image={props.source}

    />
    <CardContent>
      <Typography gutterBottom variant="h5" component="div">
        {props.service}
      </Typography>

    </CardContent>
    <CardActions>
      {/* <Link to="/patent" > */}
      <Button size="small" onClick={() => {
        history.push(`/${props.link}`);
      }}>Learn More</Button>

    </CardActions>
  </Card>)
}
const Services = () => {
  return (
    <div>
      <Container style={{ display: 'flex', flexDirection: 'column', gap: '1.5rem', }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '2rem' }}>
          <h1 >Our Services</h1>

        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', gap: '1.5rem', marginBottom: '1.5rem' }}>
          <CardContentInfo
            service={'PATENT'} link={'patent'} source={'https://imageio.forbes.com/specials-images/imageserve/1138155024/0x0.jpg?format=jpg&width=1200'} />
          <CardContentInfo service={'DESIGN'} link={'design'} source={'https://www.istitutomarangoni.com/marangoni/entities/article/CS-INTERIOR-DESIGN-JOB_IMG_D.jpg'} />

          <CardContentInfo service={'TRADEMARK'} link={'trademark'} source={'https://assets-news.housing.com/news/wp-content/uploads/2022/06/14174432/shutterstock_2032604168-1200x700-compressed.jpg'} />
          <CardContentInfo service={'COMMERCIALIZATION'} link={'commercialisation'} source={'https://iaam.se/img/Commercializing%20Technology/Your%20idea%20our%20productionzation.jpg'} />
        </div>

      </Container>
    </div>
  )
}

export default Services
